import { createStore, applyMiddleware } from "redux";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { reduxFirestore, getFirestore } from "redux-firestore";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers/rootReducer";
import thunk from "redux-thunk";
import firebase from '../config/firebase';

const rrfConfig = {
  userProfiles : 'users',
  attachAuthIsReady : true,
  useFirestoreForProfile : true
}

export const configureStore = () => {
  const middlewares = [thunk.withExtraArgument({ getFirestore ,getFirebase})];
  const composedEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares),
    reactReduxFirebase(firebase,rrfConfig),
    reduxFirestore(firebase)
  );
  const store = createStore(rootReducer, composedEnhancer);
  return store;
  
};
