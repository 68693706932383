import React, { Fragment } from "react";
import Footer from "./Footer/MainFooter";
import Main from "./Main"
import UpComingEvent from "./UpComingEvent/UpComingEvent"
import ConectWithUs from "./ConnectWithUs"


function Home() {
    return (
      <Fragment>
        <Main/>
        <UpComingEvent/>
        <ConectWithUs/>
        <Footer />
      </Fragment>
    )
}
export default Home;