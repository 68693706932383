import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom"

const BottomFooter = () => {
  return (
    <Row
      type="flex"
      align="middle"
      style={{
        backgroundColor: "#F2F2F2",
        padding: "15px 0px 15px 0px"
      }}
    >
      <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
      <Col xs={22} sm={22} md={20} lg={20} xl={20}>
        <Row type="flex" align="middle">
          <Col
            xs={24}
            md={0}
            style={{ textAlign: "center" }}
          >
            <div className="footer-text-small" style={{ fontSize:"15px" , marginBottom: "20px" }}>
            ©GL Injoy Nurse (M) Sdn Bhd. 2020
            </div>
            
          </Col>
          <Col
            xs={0}
            md={24}
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            <div className="footer-title">
            ©GL Injoy Nurse (M) Sdn Bhd. 2020
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>

      


      <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
      <Col xs={22} sm={22} md={20} lg={20} xl={20}>
        <Row type="flex" align="middle">
          <Col
            xs={24}
            md={0}
            style={{ textAlign: "center" }}
          >
            <div className="footer-text-small" style={{ fontSize:"15px" , marginBottom: "20px" }}>
            <Link to="/terms-and-conditions">
            Terms & Condition
            </Link>
            </div>
            
          </Col>
          <Col
            xs={0}
            md={24}
            style={{ textAlign: "center", marginBottom: "15px" }}
          >
            <div className="footer-title">
            <Link to="/terms-and-conditions">
            Terms & Condition
            </Link>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
    </Row>
  );
};

export default BottomFooter;
