import {
    GET_UPCOMING_EVENT,
} from './UpComingEventConstants'
import { createReducer } from "../app/common/util/reducerUtils"

const initialState = {
  upcomingEvents: []
}

const getUpcomingEvents = (state, payload) => {
  return {
    ...state,
    upcomingEvents: payload
}
  }

  
  export default createReducer(initialState, {
    [GET_UPCOMING_EVENT]: getUpcomingEvents,
  });