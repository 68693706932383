import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBCB52NVNojKt6v7RU8wcsUf4FJ8nw9k5M",
  authDomain: "prd-missoxy-my.firebaseapp.com",
  databaseURL: "https://prd-missoxy-my.firebaseio.com",
  projectId: "prd-missoxy-my",
  storageBucket: "prd-missoxy-my.appspot.com",
  messagingSenderId: "749438048967",
  appId: "1:749438048967:web:405642be35dd9c0ed0fce2"
};

  firebase.initializeApp(firebaseConfig);
  firebase.firestore();

  export default firebase;