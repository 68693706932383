import React, { Fragment } from "react";
import "./App.css";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./Home"
import TermsConditon from "./TermsCondition"


function App() {
  return (
    <Fragment>
      <Switch>

      </Switch>
      <Route exact path ="/" component={Home}/>
      <Route exact path ="/terms-and-conditions" component={TermsConditon}/>
    </Fragment>
  );
}

export default withRouter(App);
