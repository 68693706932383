import React, { Component, Fragment } from "react";
import { Row, Col, Input, Button, Form, Typography, notification } from "antd";
import BottomFooter from "./BottomFooter";
import { connect } from "react-redux";
import { subscribeNewsletter } from "./MainFooterActions";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone
} from "@ant-design/icons";
const { Text } = Typography;

const key = "subscribeNotification";

const mapStateToProps = state => {
  return {
    mainFooter: state.mainFooter
  };
};

const mapDispatchToProps = {
  subscribeNewsletter
};

class MainFooter extends Component {
  state = { reset: false };

  form = React.createRef();

  handleSubmit = () => {
    this.form.current.validateFields().then(values => {
      notification.open({
        key,
        message: "Subscribing",
        duration: 0,
        icon: <LoadingOutlined />
      });
      console.log(values);
      this.props.subscribeNewsletter(values);
      this.setState({
        reset: true
      });
    });
  };

  componentDidUpdate() {
    if (
      !this.props.mainFooter.loading &&
      this.props.mainFooter.subscribeSuccess &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Success",
        description:
          "Successfully subscribed to Missoxy newsletter. You will now receive the latest newsletter in your email.",
        icon: (
          <CheckCircleTwoTone
            twoToneColor="
        #52c41a"
          />
        )
      });
      this.form.current.resetFields();
      this.setState({
        reset: false
      });
    } else if (
      !this.props.mainFooter.loading &&
      this.props.mainFooter.subscribeFailure &&
      this.state.reset
    ) {
      notification.open({
        key,
        message: "Failed",
        description: this.props.mainFooter.errMsg,
        duration: 3,
        icon: (
          <CloseCircleTwoTone
            twoToneColor="
        #ff5255"
          />
        )
      });
      this.form.current.resetFields();
      this.setState({
        reset: false
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Row
          type="flex"
          align="stretch"
          style={{
            width: "100%",
            backgroundColor: "#F2F2F2"
          }}
        >
          <Col xs={1} sm={1} md={1} lg={1} xl={1} />
          <Col xs={22} sm={22} md={22} lg={22} xl={22}>
            <Row>
              <Col
                xs={0}
                lg={24}
                style={{
                  height: "2px",
                  backgroundColor: "#979393",
                  marginBottom: "50px"
                }}
              />
              <Col
                xs={24}
                md={24}
                lg={0}
                style={{
                  height: "2px",
                  backgroundColor: "#979393",
                  marginTop: "60px",
                  marginBottom: "25px"
                }}
              />
              <Col xs={1} sm={1} md={1} lg={1} xl={1} />
              <Col
                xs={22}
                sm={22}
                md={22}
                lg={6}
                xl={6}
                style={{ paddingTop: "5px", height: "100%" }}
              >
                <div className="footer-text-head" style={{ marginTop: "15px" }}>
                  Subscribe to our newsletter
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Form
                    ref={this.form}
                    onFinish={this.handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <Row>
                      <Col
                        xs={24}
                        xl={24}
                        style={{ paddingTop: "5px", height: "100%" }}
                      >
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not a valid email!"
                            },
                            {
                              required: true,
                              message: "Please provide your email to subscribe!"
                            }
                          ]}
                        >
                          <Input
                            placeholder="Your Email Address"
                            className="subscribe-input-placeholder"
                            style={{
                              height: "50px",
                              borderColor: "#FFB0A9",
                              borderWidth: "2px",
                              fontFamily: "montserrat-bold",
                              fontSize: "20px",
                              textAlign: "center"
                            }}
                          />
                        </Form.Item>
                        <Button
                          block
                          htmlType="submit"
                          size="large"
                          disabled={this.props.mainFooter.loading}
                          style={{
                            height: "50px",
                            fontFamily: "montserrat-bold",
                            fontSize: "20px",
                            backgroundColor : "#FFB0A9"
                          }}
                        >
                          <Text style={{color:"white"}}>Subscribe</Text>
                          
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
              <Col xs={0} sm={0} md={0} lg={1} xl={1} />
              <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                <br />
                <br />
              </Col>
              <Col xs={22} sm={22} md={22} lg={5} xl={5}>
                <Text className="footer-text-head" style={{ color: "#6C6A6A" }}>
                  Location
                </Text>
                <br />
                <Text
                  className="footer-text-small"
                  style={{ color: "#6C6A6A" }}
                >
                  A10-05, EkoCheras
                  <br />
                  No.693, Batu 5,
                  <br />
                  Jalan Cheras
                  <br />
                  Kuala Lumpur
                  <br />
                  +60 10 665 3822
                  <br />
                  glinjoynurse@gmail. com
                </Text>
              </Col>

              <Col xs={0} sm={0} md={0} lg={1} xl={1} />
              <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                <br />
                <br />
              </Col>
              <Col xs={22} sm={22} md={22} lg={5} xl={5}>
                <Text className="footer-text-head" style={{ color: "#6C6A6A" }}>
                  Hours
                </Text>
                <br />
                <Text
                  className="footer-text-small"
                  style={{ color: "#6C6A6A" }}
                >
                  Mon - Fri 9:30am - 6:30pm
                  <br />
                  Sat 9:30am - 1:30pm
                </Text>
              </Col>

              <Col xs={0} sm={0} md={0} lg={1} xl={1} />
              <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                <br />
                <br />
              </Col>
              <Col xs={22} sm={22} md={22} lg={4} xl={4}>
                <Text className="footer-text-head" style={{ color: "#6C6A6A" }}>
                  Connect with us
                </Text>
                <br />
                <img
                  style={{ marginRight: "12px" }}
                  src="/assets/fb_icon.png"
                  alt="Facebook"
                />
                <img
                  style={{ marginRight: "12px" }}
                  src="/assets/ig_icon.png"
                  alt="Instagram"
                />
                <img src="/assets/pinterest_icon.png" alt="Pinterest" />
              </Col>
              <Col
                xs={0}
                lg={24}
                style={{
                  height: "2px",
                  backgroundColor: "#979393",
                  marginTop: "80px",
                  marginBottom: "50px"
                }}
              />
              <Col
                xs={0}
                md={24}
                lg={0}
                style={{
                  height: "2px",
                  backgroundColor: "#979393",
                  marginTop: "60px",
                  marginBottom: "25px"
                }}
              />
              <Col
                xs={24}
                md={0}
                style={{
                  height: "2px",
                  backgroundColor: "#979393",
                  marginTop: "60px",
                  marginBottom: "25px"
                }}
              />
            </Row>
          </Col>
        </Row>
        <BottomFooter />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainFooter);
