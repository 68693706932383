import React from "react";
import { Row, Col } from "antd";

const BottomFooter = () => {
  return (
    <Row
      type="flex"
      align="middle"
      style={{
        backgroundColor: "#F2F2F2",
        padding: "40px 0px 40px 0px"
      }}
    >
      <Col xs={1} sm={1} md={2} lg={4} xl={4}></Col>
      <Col xs={22} sm={22} md={20} lg={16} xl={16} style={{backgroundColor:"White"}}>
        <Row type="flex" align="middle">
          <Col style={{padding:"30px"}} >
            <div className="terms-head" style={{ fontSize:"24px" , marginBottom: "20px" }}>
            MISSOXY APP – PRIVACY POLICY
            </div>
            <p>This page informs you of MISSOXY e-Store policies regarding the collection, use, and disclosure of personal data when you use our services. We value your privacy and share our personal data practices in this privacy policy statement to let you know how we use any personal information provided by you to us.</p>
            <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px" }}>
            1. THE INFORMATION WE COLLECT
            </div>
            <p>While using our services, we may request personal information including but not limited to your name, address, contact information as well as the relevant information that would help us render the service to you.</p>
            <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px" }}>
            2. USE OF INFORMATION
            </div>
            <p>Upon giving us consent, we may use the collected data for the following purposes:</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To enable you to access and use the features of our platform</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To provide and maintain our services</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To provide analysis so that we can improve our services</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To monitor the usage of our services</p>
            <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px" }}>
            3. DISCLOSURE OF DATA
            </div>
            <p>We will never sell, distribute, disseminate, disclose or lease your personal information to third parties. However, we may disclose your Personal Data in good faith that such action is necessary:</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To comply with legal obligation</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To protect and defend the rights or property of MISSOXY e-Store</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To prevent or investigate possible wrong-doing in connection with our services</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To protect the safety of users of our services or the public</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />To complete item purchase via payment gateway(exp:IPAY88)</p>
            <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px" }}>
            4. KEEPING YOU INFORMED
            </div>
            <p>You agree that we shall be entitled to advise you via email, post, telephone or by text of products, services and other offers we think may interest you. If you do not wish to be contacted by us, you may email us at <a href="mailto:missoxymalaysia@hotmail.com" style={{ textDecoration: "underline" }}>missoxymalaysia@hotmail.com</a>.</p>
            <div className="terms-head" style={{ fontSize:"22px" , marginBottom: "20px" }}>
            5. WEBSITE BROWSING AND COOKIES
            </div>
            <p>A cookie is a very small text file placed in your computer. Cookies help us to:</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />understand the browsing habits of visitors to our Website;</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />understand the number of visitors to this Website and the different pages visited; and</p>
            <p><img src="/assets/dot.png" alt="dot" width="13px" style={{paddingRight: "4px"}} />remember you when you return to this Website so we can provide you with a better browsing experience.</p>
            <p>You can choose to block or delete cookies through your browser settings. If you decide to block or delete our cookies, you may not be able to benefit from the full range of our services and this may affect the performance of our website on your system.</p>

          </Col>
        </Row>
      </Col>
      <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>

    </Row>
  );
};

export default BottomFooter;
