import React, { Component, Fragment } from "react";
import { Row, Col, Typography, Spin } from "antd";
import { getUpcomingEvents } from "./UpComingEventActions";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
const { Text } = Typography;
var moment = require("moment");

const eventPhotos = [];
var eventDateResult = []

const mapStatetoProps = state => ({
  upcomingEventList: state.upcomingEvents.upcomingEvents
});

const mapDispatchToProps = {
  getUpcomingEvents
};

class UpComingEvent extends Component {
  state = {
    loading: true,
    loadedEventList: this.props.upcomingEventList,
    eventDateResult: eventDateResult
  };

  async componentDidMount() {
    if (this.props.upcomingEventList.length !== 0) {
      this.setState({
        loading: false,
        loadedEventList: this.props.upcomingEventList
      });
    } else await this.props.getUpcomingEvents();
  }

  async componentDidUpdate(prevProps) {
    const result = [];
    if (this.props.upcomingEventList !== prevProps.upcomingEventList) {
      console.log("Change Triggered");

      var eventList = this.props.upcomingEventList;
      eventList.forEach(event => {
        var eventDateMutableMap = new Map();
        var eventDateMapKeyList = [];
        var sortedDate = event.eventDateList;
        var dayDelimiter = "-";

        sortedDate.forEach(iteratedDate => {
          var dateKey = moment(
            new Date(parseInt(iteratedDate.seconds * 1000, 10))
          ).format("MMM 'YY");
          var dateValue = moment(
            new Date(parseInt(iteratedDate.seconds * 1000, 10))
          ).format("DD");

          if (eventDateMutableMap.hasOwnProperty(dateKey)) {
            var storedDayValue = eventDateMutableMap[dateKey].split(",");
            var lastStoredDayValue = storedDayValue[storedDayValue.length - 1];
            if (lastStoredDayValue.includes(dayDelimiter)) {
              var lastStoredDayValueWithDayDelimiter = lastStoredDayValue.split(
                dayDelimiter
              );
              var lastElementFromLastStoredDayValueWithDayDelimiter =
                lastStoredDayValueWithDayDelimiter[
                  lastStoredDayValueWithDayDelimiter.length - 1
                ];

              if (
                parseInt(dateValue) -
                  parseInt(
                    lastElementFromLastStoredDayValueWithDayDelimiter
                  ) ===
                1
              ) {
                lastStoredDayValueWithDayDelimiter.splice(
                  lastStoredDayValueWithDayDelimiter.length - 1
                );
                lastStoredDayValueWithDayDelimiter.push(dateValue);
                storedDayValue[
                  storedDayValue.length - 1
                ] = lastStoredDayValueWithDayDelimiter.join(dayDelimiter);
                eventDateMutableMap[dateKey] = storedDayValue.join(",");
              } else if (
                parseInt(dateValue) -
                  parseInt(lastElementFromLastStoredDayValueWithDayDelimiter) >
                1
              ) {
                storedDayValue.push(dateValue);
                eventDateMutableMap[dateKey] = storedDayValue.join(",");
              }
            } else {
              if (parseInt(dateValue) - parseInt(lastStoredDayValue) === 1) {
                storedDayValue[
                  storedDayValue.length - 1
                ] = `${lastStoredDayValue}${dayDelimiter}${dateValue}`;
                eventDateMutableMap[dateKey] = storedDayValue.join(",");
              } else if (
                parseInt(dateValue) - parseInt(lastStoredDayValue) >
                1
              ) {
                storedDayValue.push(dateValue);
                eventDateMutableMap[dateKey] = storedDayValue.join(",");
              }
            }
          } else {
            eventDateMutableMap[dateKey] = dateValue;
            eventDateMapKeyList.push(dateKey);
          }
        });
        var map = [];
        
        eventDateMapKeyList.forEach(iteratedDateKey => {
          map.push(eventDateMutableMap[iteratedDateKey]);
          map.push(iteratedDateKey);
          
          result.push(map);
          
        });

      });

      let storageRef = this.props.firebase.storage().ref();
      let eventPhotoUrlsPromises = [];
      eventList.forEach(event => {
        eventPhotoUrlsPromises.push(
          storageRef.child(event.eventImagePath).getDownloadURL()
        );
      });

      let servicePhotoUrls = await Promise.all(eventPhotoUrlsPromises);
      servicePhotoUrls.forEach(url => {
        eventPhotos.push(url);
      });

      if (servicePhotoUrls.length > 0) {
        this.setState({
          loading: false,
          loadedEventList: this.props.upcomingEventList,
          eventDateResult: result
        });
        eventDateResult = result
      }
    }
  }

  render() {
    const eventDateResult = this.state.eventDateResult;
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spin />
        </div>
      );
    }
    return (
      <Fragment>
        <Row
          type="flex"
          align="stretch"
          style={{
            width: "100%",
            paddingTop: "50px"
          }}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontFamily: "montserrat-bold",
                fontSize: "40px",
                color: "#6C6A6A"
              }}
            >
              Upcoming Events
            </Text>
          </Col>
        </Row>
        <Row
          type="flex"
          align="stretch"
          style={{
            width: "100%",
            padding: "50px"
          }}
        >
          {this.state.loadedEventList.map((event, idx) => (
            <Col
              md={0}
              sm={0}
              xs={0}
              xl={12}
              lg={12}
              style={{ padding: "20px", paddingLeft: "100px"}}
              key={idx}
            >
              <img width="100%" src={eventPhotos[idx]} alt="Up Coming Event" />
              <Row>
                <Col md={0} sm={0} xs={0} xl={24} lg={24}>
                  <Text
                    style={{
                      fontSize: "28px",
                      fontFamily: "montserrat-semibold"
                    }}
                  >
                    {event.eventName}
                  </Text>
                </Col>
                <Col md={0} sm={0} xs={0} xl={12} lg={0}>
                  <Text
                    style={{
                      fontSize: "26px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >{`${event.eventAddress.city}, ${event.eventAddress.country}`}</Text>
                </Col>
                <Col
                  style={{ textAlign: "right" }}
                  md={0}
                  sm={0}
                  xs={0}
                  xl={12}
                  lg={0}
                >
                  <Text
                    style={{
                      fontSize: "24px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >
                    {eventDateResult[idx].map((item, id) => ( item.includes("'") ? ( id + 1 === eventDateResult[idx].length ? (`${item}`) : (`${item}, `)) : (`${item} `) ))}
                  </Text>
                </Col>
                <Col md={0} sm={0} xs={0} xl={0} lg={24}>
                  <Text
                    style={{
                      fontSize: "20px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >{`${event.eventAddress.city}, ${event.eventAddress.country}`}</Text>
                </Col>
                <Col
                  style={{ textAlign: "left" }}
                  md={0}
                  sm={0}
                  xs={0}
                  xl={0}
                  lg={24}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >
                    
                    {eventDateResult[idx].map((item, id) => ( item.includes("'") ? ( id + 1 === eventDateResult[idx].length ? (`${item}`) : (`${item}, `)) : (`${item} `) ))}
                  </Text>
                </Col>
              </Row>
            </Col>
          ))}
          {this.state.loadedEventList.map((event, idx) => (
            <Col
              md={24}
              sm={24}
              xs={24}
              xl={0}
              lg={0}
              style={{ padding: "20px" }}
              key={idx}
            >
              <img width="100%" src={eventPhotos[idx]} alt="Up Coming Event " />
              <Row>
                <Col md={24} sm={24} xs={0} xl={0} lg={0}>
                  <Text
                    style={{
                      fontSize: "28px",
                      fontFamily: "montserrat-semibold"
                    }}
                  >
                    {event.eventName}
                  </Text>
                </Col>
                <Col md={24} sm={24} xs={0} xl={0} lg={0}>
                  <Text
                    style={{
                      fontSize: "26px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >{`${event.eventAddress.city}, ${event.eventAddress.country}`}</Text>
                </Col>
                <Col
                  md={24}
                  sm={24}
                  xs={0}
                  xl={0}
                  lg={0}
                >
                  <Text
                    style={{
                      fontSize: "22px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >
                    {eventDateResult[idx].map((item, id) => ( item.includes("'") ? ( id + 1 === eventDateResult[idx].length ? (`${item}`) : (`${item}, `)) : (`${item} `) ))}
                  </Text>
                </Col>
                <Col md={0} sm={0} xs={24} xl={0} lg={0}>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "montserrat-semibold"
                    }}
                  >
                    {event.eventName}
                  </Text>
                </Col>
                <Col md={0} sm={0} xs={24} xl={0} lg={0}>
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >{`${event.eventAddress.city}, ${event.eventAddress.country}`}</Text>
                </Col>
                <Col
                  md={0}
                  sm={0}
                  xs={24}
                  xl={0}
                  lg={0}
                >
                  <Text
                    style={{
                      fontSize: "18px",
                      fontFamily: "montserrat-regular",
                      color: "#675858"
                    }}
                  >
                    {eventDateResult[idx].map((item, id) => ( item.includes("'") ? ( id + 1 === eventDateResult[idx].length ? (`${item}`) : (`${item}, `)) : (`${item} `) ))}
                  </Text>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Fragment>
    );
  }
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withFirebase(UpComingEvent));
