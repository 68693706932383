import React, { Component, Fragment } from "react";
import { Row, Col, Typography, Button } from "antd";
const { Text } = Typography;
class Main extends Component {
  render() {
    return (
      <Fragment>
        <Row
    
          type="flex"
          align="stretch"
          style={{
            width: "100%",
            backgroundColor: "#F2F2F2"
          }}
        >
            <Col md={0} sm={0} xs={0} xl={16} lg={0} style={{ textAlign: "right" }}>
            <img style={{padding:"10px", paddingBottom:"30px", height:"140px", width:"auto"}} src="/assets/logo.png" alt="Logo"/>
            <Text  style={{fontSize:"40px", fontFamily:"montserrat-semibold", color:"#6C6A6A", marginBottom:"100px"}}>GL INJOY NURSE</Text>
            
           
            </Col>
            <Col md={0} sm={0} xs={0} xl={8} lg={0} style={{ textAlign: "end", paddingTop:"10px", paddingRight:"20px" }}>
                <Text className="footer-text-head" style={{ color: "#6C6A6A"}}>
                Connect with us
                </Text>
                <br />
                <img style={{marginRight:"15px", width:"auto", height:"43px", paddingTop:"8px"}} src="/assets/fb_icon.png" alt="Facebook"/>
                <img style={{marginRight:"15px", width:"auto",  height:"43px", paddingTop:"8px"}} src="/assets/ig_icon.png" alt="Instagram"/>
                <img style={{width:"auto",  height:"43px", paddingTop:"8px"}} src="/assets/pinterest_icon.png" alt="Pinterest"/>
              </Col>


              <Col md={0} sm={0} xs={0} xl={0} lg={16} style={{ textAlign: "right" }}>
            <img width="13%" style={{padding:"10px"}} src="/assets/logo.png" alt="Logo"/>
            <Text  style={{fontSize:"30px", fontFamily:"montserrat-semibold", color:"#6C6A6A", marginBottom:"50px"}}>GL INJOY NURSE</Text>
            
           
            </Col>
            <Col md={0} sm={0} xs={0} xl={0} lg={7} style={{ textAlign: "right" }}>
                <Text style={{ color: "#6C6A6A", fontSize:"25px", fontFamily:"montserrat-medium" }}>
                Connect with us
                </Text>
                <br />
                <img width="10%" style={{marginRight:"15px"}} src="/assets/fb_icon.png" alt="Facebook"/>
                <img  width="10%"style={{marginRight:"15px"}} src="/assets/ig_icon.png" alt="Instagram"/>
                <img  width="10%"src="/assets/pinterest_icon.png" alt="Pinterest"/>
              </Col>
            


            <Col md={16} sm={16} xs={0} xl={0} lg={0}>
            <img width="100px" style={{padding:"20px"}} src="/assets/logo.png" alt="Logo"/>
            <Text style={{fontSize:"30px", fontFamily:"montserrat-semibold", color:"#6C6A6A", }}>GL INJOY NURSE</Text>
            </Col>
            <Col md={7} sm={7} xs={0} xl={0} lg={0} style={{textAlign:"end"}}>
                <Text className="footer-text-small" style={{ color: "#6C6A6A"}}>
                Connect with us
                </Text>
                <br />
                <img width="30px" style={{marginRight:"15px"}} src="/assets/fb_icon.png" alt="Facebook"/>
                <img width="30px"  style={{marginRight:"15px"}} src="/assets/ig_icon.png" alt="Instagram"/>
                <img width="30px" src="/assets/pinterest_icon.png" alt="Pinterest"/>
              </Col>

            <Col  md={0} sm={0} xs={24} xl={0} lg={0}>
            <img width="85px" style={{padding:"20px"}} src="/assets/logo.png" alt="Logo"/>
            <Text style={{fontSize:"25px", fontFamily:"montserrat-semibold", color:"#6C6A6A", }}>GL INJOY NURSE</Text>
            </Col>
            <Col offset={1} md={0} sm={0} xs={23} xl={0} lg={0} style={{paddingBottom:"10px" }}>
                <Text className="footer-text-small" style={{ color: "#6C6A6A" }}>
                Connect with us
                </Text>
                <br />
                <img width="30px" style={{marginRight:"15px"}} src="/assets/fb_icon.png" alt="Facebook"/>
                <img  width="30px"style={{marginRight:"15px"}} src="/assets/ig_icon.png" alt="Instagram"/>
                <img width="30px" style={{paddingBlock:"15px"}} src="/assets/pinterest_icon.png" alt="Pinterest"/>
              </Col>

              
        </Row>
        <Row style={{
            width: "100%",
            backgroundColor: "#F2F2F2",
            
          }}>
            {/* <Col md={0} sm={0} xs={0} xl={24} lg={24} style={{paddingLeft:"140px", paddingRight:"140px"}}>
            <img width="100%" src="/assets/missoxy_main.jpg" alt="Missoxy"/>
            </Col> */}
            <Col span = {24} >
            <img width="100%" src="/assets/missoxy_main.jpg" alt="Missoxy"/>
            </Col>

        </Row >
        

        <Row
    
          type="flex"
          align="stretch"
          style={{
            width: "100%",
            backgroundColor: "#F2F2F2",
            paddingTop:"70px"
          }}
        >
            <Col md={0} sm={0} xs={0} xl={24} lg={24} style={{ textAlign: "center", paddingLeft:"240px", paddingRight:"240px" }}>
                <Text style={{fontFamily:"montserrat_regular", fontSize:"28px", color:"#6C6A6A"}}>GL Injoy Nurse (M) Sdn Bhd is an international company integrating R&D, production and sales. Mainly from Patent-based hygiene products and medical-level private care projects, the company promotes Chinese traditional culture as the cornerstone of enterprise development, caring for women’s health, promoting global sharing of happy homes as their responsibility, products are exported to many countries and regions. Company headquarters still France, Japan, South Korea, Indonesia, Thailand, Malaysia, Singapore, and Hong Kong have branches, and the business radiates globally.
                <br/><br/>
                Missoxy is a wholly owned subsidiary of GL Injoy Nurse (M) Sdn Bhd
                </Text>
            </Col>

            <Col md={24} sm={24} xs={24} xl={0} lg={0} style={{ textAlign: "center", paddingLeft:"40px", paddingRight:"40px" }}>
                <Text style={{fontFamily:"montserrat_regular", fontSize:"20px", color:"#6C6A6A"}}>GL Injoy Nurse (M) Sdn Bhd is an international company integrating R&D, production and sales. Mainly from Patent-based hygiene products and medical-level private care projects, the company promotes Chinese traditional culture as the cornerstone of enterprise development, caring for women’s health, promoting global sharing of happy homes as their responsibility, products are exported to many countries and regions. Company headquarters still France, Japan, South Korea, Indonesia, Thailand, Malaysia, Singapore, and Hong Kong have branches, and the business radiates globally.
                <br/><br/>
                Missoxy is a wholly owned subsidiary of GL Injoy Nurse (M) Sdn Bhd
                </Text>
            </Col>

        </Row>
        <Row type="flex"
          align="stretch"
          style={{
            width: "100%",
            backgroundColor: "#F2F2F2",
            paddingTop:"50px", paddingBottom:"50px"
          }}>
        <Col md={24} sm={0} xs={0} xl={24} lg={24} style={{ textAlign: "center" }}>
        <a
                      href="https://missoxy.my/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                <Button size="large" style={{borderColor:"#FFB0AB", fontFamily:"montserrat_bold", fontSize:"32px", borderWidth:"2px", color:"#FFB0AB", width:"600px", height: "90px", padding:"10px"}}  >Enter Our Missoxy Official Site</Button>
                </a>
                </Col>

                <Col md={0} sm={24} xs={24} xl={0} lg={0} style={{ textAlign: "center" }}>
                <a
                      href="https://missoxy.my/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                <Button size="large" style={{borderColor:"#FFB0AB", fontFamily:"montserrat_bold", fontSize:"20px", borderWidth:"2px", color:"#FFB0AB", height: "60px"}}  >Enter Our Missoxy Official Site</Button>
                </a>
                </Col>
        </Row>
      </Fragment>
    );
  }
}

export default Main;