import { combineReducers } from 'redux';
import { firebaseReducer } from "react-redux-firebase";
import upcomingEventReducer from '../../UpComingEvent/UpComingEventReducer';
import mainFooterReducer from '../../Footer/MainFooterReducer';

const rootReducer = combineReducers({
    firebase : firebaseReducer,
    upcomingEvents: upcomingEventReducer,
    mainFooter: mainFooterReducer,
})

export default rootReducer;