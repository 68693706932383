import { GET_UPCOMING_EVENT } from "./UpComingEventConstants";

export const getUpcomingEvents = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    console.log("Start");
    const firestore = getFirestore();

    try {
      let eventRef = await firestore.get({ collection: "missoxy_events_en" });
      let upcomingEventList = [];

     await eventRef.forEach(async docSnap => {
          let id = docSnap.id;
          upcomingEventList.push({
            ...docSnap.data(),
            id,
          });
        
      });
      console.log(upcomingEventList)
      dispatch({ type: GET_UPCOMING_EVENT, payload: upcomingEventList });
    } catch (err) {
      console.log(err);
    }
  };
};
