import {
  SUBSCRIBE_START,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBE_FAILURE
} from "./MainFooterConstants";

export const subscribeNewsletter = email => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({ type: SUBSCRIBE_START });
    const firestore = getFirestore();

    firestore
      .get({ collection: "subscribers", doc: `${email.email}` })
      .then(docSnap => {
        console.log(docSnap);

        if (docSnap.exists) {
          dispatch({
            type: SUBSCRIBE_FAILURE,
            payload: {
              errMsg: "That email has already been used to subscribe to our newsletter."
            }
          });
        } else {
          firestore
            .set(
              { collection: "subscribers", doc: `${email.email}` },
              { ...email }
            )
            .then(() => {
              dispatch({ type: SUBSCRIBE_SUCCESS });
            })
            .catch(err => {
              console.log(err);
              dispatch({
                type: SUBSCRIBE_FAILURE,
                payload: {
                  errMsg:
                    "Error occured while subscribing. Please try again later."
                }
              });
            });
        }
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: SUBSCRIBE_FAILURE,
          payload: {
            errMsg:
              "Error occured while subscribing. Please try again later."
          }
        });
      });
  };
};
