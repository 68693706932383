import React, { Component, Fragment } from "react";
import { Row, Col, Typography } from "antd";
const { Text } = Typography;
class ConnectWithUs extends Component {
  render() {
    return (
      <Fragment>
        <Row
          type="flex"
          align="stretch"
          style={{
            width: "100%",
            backgroundColor: "#F2F2F2",
            padding: "50px"
          }}
        >
          <Col
            xs={0}
            sm={0}
            md={24}
            xl={24}
            lg={24}
            style={{ textAlign: "center", padding: "120px" }}
          >
            <Row
              type="flex"
              align="stretch"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "50px"
              }}
            >
              <Col
                xs={0}
                sm={0}
                md={0}
                xl={12}
                lg={0}
                style={{ textAlign: "center", padding: "50px" }}
              >
                <Text
                  style={{
                    fontSize: "30px",
                    fontFamily: "montserrat-bold",
                    color: "#6C6A6A"
                  }}
                >
                  {" "}
                  <br />
                  Connect with us{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "20px",
                    fontFamily: "montserrat-regular",
                    color: "#6C6A6A"
                  }}
                >
                  <br />
                  Due to Corvid 19 <br />
                  <br />
                  For those that are challenged to get into our space. Just as
                  we currently evolve into life at home in solitude, we also
                  need to adapt and open ourselves to a new way of serving,
                  educating and caring for you.
                  <br />
                  <br /> We are now proudly introducing our mobile apps!{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "30px",
                    fontFamily: "montserrat-semibold",
                    color: "#6C6A6A"
                  }}
                >
                  {" "}
                  <br /> <br /> <br /> Download our apps now!{" "}
                </Text>{" "}
                <br /> <br />
                <Row type="flex">
                  <Col
                    xs={0}
                    sm={0}
                    md={0}
                    xl={12}
                    lg={0}
                    style={{ padding: "50px" }}
                  >
                    <a
                      href="https://play.google.com/store?hl=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/google-play-badge.png"
                        alt=""
                        style={{ width: "auto", height: "50px" }}
                      />
                    </a>
                  </Col>
                  <Col
                    xs={0}
                    sm={0}
                    md={0}
                    xl={12}
                    lg={0}
                    style={{ textAlign: "right", padding: "50px" }}
                  >
                    <a
                      href="https://www.apple.com/my/ios/app-store/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/apple_download-3.png"
                        alt=""
                        style={{ width: "auto", height: "50px" }}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={0}
                sm={0}
                md={0}
                xl={0}
                lg={12}
                style={{ textAlign: "center", padding: "30px" }}
              >
                <Text
                  style={{
                    fontSize: "30px",
                    fontFamily: "montserrat-bold",
                    color: "#6C6A6A"
                  }}
                >
                  Connect with us{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "20px",
                    fontFamily: "montserrat-regular",
                    color: "#6C6A6A"
                  }}
                >
                  <br />
                  Due to Corvid 19 <br />
                  <br />
                  For those that are challenged to get into our space. Just as
                  we currently evolve into life at home in solitude, we also
                  need to adapt and open ourselves to a new way of serving,
                  educating and caring for you.
                  <br />
                  <br /> We are now proudly introducing our mobile apps!{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "30px",
                    fontFamily: "montserrat-semibold",
                    color: "#6C6A6A"
                  }}
                >
                  {" "}
                  <br /> <br /> <br /> Download our apps now!{" "}
                </Text>{" "}
                <br /> <br />
                <Row type="flex">
                  <Col
                    xs={0}
                    sm={0}
                    md={0}
                    xl={0}
                    lg={24}
                    style={{ textAlign: "center", padding: "50px" }}
                  >
                    <a
                      href="https://www.apple.com/my/ios/app-store/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                      <img
                        src="/assets/google_play.png"
                        alt=""
                        style={{ width: "auto", height: "60px" }}
                      />
                    </a>
                    <p />
                    <a
                      href="https://www.apple.com/my/ios/app-store/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                      <img
                        src="/assets/apple_download-3.png"
                        alt=""
                        style={{ width: "auto", height: "60px" }}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>

              <Col
                xs={0}
                sm={0}
                md={24}
                xl={0}
                lg={0}
                style={{ textAlign: "center", padding: "30px" }}
              >
                <Text
                  style={{
                    fontSize: "25px",
                    fontFamily: "montserrat-bold",
                    color: "#6C6A6A"
                  }}
                >
                  Connect with us{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "15px",
                    fontFamily: "montserrat-regular",
                    color: "#6C6A6A"
                  }}
                >
                  <br />
                  Due to Corvid 19 <br />
                  <br />
                  For those that are challenged to get into our space. Just as
                  we currently evolve into life at home in solitude, we also
                  need to adapt and open ourselves to a new way of serving,
                  educating and caring for you.
                  <br />
                  <br /> We are now proudly introducing our mobile apps!{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "25px",
                    fontFamily: "montserrat-semibold",
                    color: "#6C6A6A"
                  }}
                >
                  {" "}
                  <br /> Download our apps now!{" "}
                </Text>{" "}
                <br />
                <Row type="flex">
                  <Col
                    xs={0}
                    sm={0}
                    md={24}
                    xl={0}
                    lg={0}
                    style={{ padding: "30px" }}
                  >
                    <a
                      href="https://www.apple.com/my/ios/app-store/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                      <img
                        src="/assets/google_play.png"
                        alt=""
                        style={{ width: "auto", height: "50px" }}
                      />
                    </a>
                    <p />
                    <a
                      href="https://www.apple.com/my/ios/app-store/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "20px" }}
                    >
                      <img
                        src="/assets/apple_download-3.png"
                        alt=""
                        style={{ width: "auto", height: "50px" }}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>

              <Col xs={0} sm={0} md={0} xl={2} lg={1}></Col>
              <Col xs={0} sm={0} md={0} xl={10} lg={0}>
                <img
                  src="assets/missoxy_mobile.png"
                  alt="Missoxy mobile"
                  style={{ width: "auto", height: "750px" }}
                ></img>
              </Col>
              <Col xs={0} sm={0} md={0} xl={0} lg={10}>
                <img
                  src="assets/missoxy_mobile.png"
                  alt="Missoxy mobile"
                  style={{ width: "auto", height: "650px" }}
                ></img>
              </Col>
              <Col xs={0} sm={0} md={24} xl={0} lg={0}>
                <img
                  src="assets/missoxy_mobile.png"
                  alt="Missoxy mobile"
                  style={{ width: "auto", height: "450px" }}
                ></img>
              </Col>

            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={0}
            xl={0}
            lg={0}
            style={{ textAlign: "center" }}
          >
            <Row
              type="flex"
              align="stretch"
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "30px"
              }}
            >
              <Col
                xs={0}
                sm={24}
                md={0}
                xl={0}
                lg={0}
                style={{ textAlign: "center", padding: "10px" }}
              >
                <Text
                  style={{
                    fontSize: "18px",
                    fontFamily: "montserrat-bold",
                    color: "#6C6A6A"
                  }}
                >
                  Connect with us{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "13px",
                    fontFamily: "montserrat-regular",
                    color: "#6C6A6A"
                  }}
                >
                  <br />
                  Due to Corvid 19 <br />
                  <br />
                  For those that are challenged to get into our space. Just as
                  we currently evolve into life at home in solitude, we also
                  need to adapt and open ourselves to a new way of serving,
                  educating and caring for you.
                  <br />
                  <br /> We are now proudly introducing our mobile apps!{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "18px",
                    fontFamily: "montserrat-semibold",
                    color: "#6C6A6A"
                  }}
                >
                  <br /> Download our apps now!{" "}
                </Text>{" "}
                <br />
              </Col>

              <Col
                xs={0}
                sm={24}
                md={0}
                xl={0}
                lg={0}
                style={{ paddingBottom: "30px", textAlign: "center" }}
              >
                <a
                  href="https://www.apple.com/my/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "20px" }}
                >
                  <img
                    src="/assets/google_play.png"
                    alt=""
                    style={{ width: "auto", height: "50px" }}
                  />
                </a>
                <p />
                <a
                  href="https://www.apple.com/my/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: "20px" }}
                >
                  <img
                    src="/assets/apple_download-3.png"
                    alt=""
                    style={{ width: "auto", height: "50px" }}
                  />
                </a>
              </Col>

              <Col
                xs={24}
                sm={0}
                md={0}
                xl={0}
                lg={0}
                style={{ textAlign: "center" }}
              >
                <Text
                  style={{
                    fontSize: "18px",
                    fontFamily: "montserrat-bold",
                    color: "#6C6A6A"
                  }}
                >
                  Connect with us{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "13px",
                    fontFamily: "montserrat-regular",
                    color: "#6C6A6A"
                  }}
                >
                  <br />
                  Due to Corvid 19 <br />
                  <br />
                  For those that are challenged to get into our space. Just as
                  we currently evolve into life at home in solitude, we also
                  need to adapt and open ourselves to a new way of serving,
                  educating and caring for you.
                  <br />
                  <br /> We are now proudly introducing our mobile apps!{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "15px",
                    fontFamily: "montserrat-semibold",
                    color: "#6C6A6A"
                  }}
                >
                  <br />
                  <br /> Download our apps now!{" "}
                </Text>{" "}
                <br />
              </Col>

              <Col
                xs={24}
                sm={0}
                md={0}
                xl={0}
                lg={0}
                style={{
                  paddingBottom: "20px",
                  paddingTop: "10px",
                  textAlign: "center"
                }}
              >
                <a
                  href="https://www.apple.com/my/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/google_play.png"
                    alt=""
                    style={{ width: "auto", height: "50px" }}
                  />
                </a>
                <p />
                <a
                  href="https://www.apple.com/my/ios/app-store/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/apple_download-3.png"
                    alt=""
                    style={{ width: "auto", height: "50px" }}
                  />
                </a>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={0}
                xl={0}
                lg={0}
                style={{ textAlign: "center" }}
              >
                <img
                  width="60%"
                  src="assets/missoxy_mobile.png"
                  alt="Missoxy mobile"
                ></img>
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default ConnectWithUs;
